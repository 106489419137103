<template>
  <div class="kw-tabs-wrapper-content">
    <h3>{{ $t("COMMON.GENERAL_INFORMATIONS") }}</h3>
    <div class="all-infos">
      <div class="all-infos-text">
        <dl class="row">
          <dt>{{ $t("COMMON.NAME") }}</dt>
          <dd>
            {{ role.name }}
          </dd>
        </dl>
        <dl class="row">
          <dt>{{ $t("COMMON.EXCERPT") }}</dt>
          <dd>
            <div v-html="role.excerpt"></div>
          </dd>
        </dl>
        <dl class="row">
          <dt>{{ $t("COMMON.LOCATIONS") }}</dt>
          <dd>
            <locations :locations="role.allowedLocations" />
          </dd>
        </dl>
        <dl
          class="row"
          v-if="$currentUserCan($permissions.PERM_VIEW_ANY_ORGANIZATIONS)"
        >
          <dt>{{ $t("COMMON.ORGANIZATION") }}</dt>
          <dd>
            <organization :organization="role.organization" />
          </dd>
        </dl>
        <dl
          class="row"
          v-if="$currentUserCan($permissions.PERM_VIEW_ANY_RESELLERS)"
        >
          <dt>{{ $t("COMMON.RESELLER") }}</dt>
          <dd>
            <reseller :reseller="role.reseller" />
          </dd>
        </dl>
        <dl class="row">
          <dt>{{ $t("COMMON.CREATED_AT") }}</dt>
          <dd v-if="role.created_at">
            {{ $formatDate(role.created_at) }}
          </dd>
        </dl>
        <dl class="row">
          <dt>{{ $t("COMMON.UPDATED_AT") }}</dt>
          <dd v-if="role.updated_at">
            {{ $formatDate(role.updated_at) }}
          </dd>
        </dl>
        <h3>{{ $t("COMMON.PERMISSIONS") }}</h3>
        <role-permissions :role="role" />
      </div>
    </div>
  </div>
</template>
<script>
import RolePermissions from "@/components/RolePermissions.vue";

export default {
  name: "role-view-global",

  components: { RolePermissions },

  props: ["role"],

  data() {
    return {};
  },

  computed: {},

  created() {},

  methods: {},

  mounted() {},

  watch: {
    role(role) {},
  },
};
</script>
