<template>
  <div class="container-fluid">
    <span class="resize-loading" v-if="!role">
      <span class="loader"></span>
    </span>
    <div v-if="role" class="kw-tabs-wrapper">
      <div class="view-details-header">
        <div class="view-details-header-left">
          <h2>{{ $t("COMMON.ROLE_DETAILS") }}</h2>
          <div class="kw-breadcrumb">
            <ul>
              <li>
                <img src="/img/kw-home.svg" alt="icon" />
                <span>{{ $t("ROLES.ROLES_LIST") }}</span>
              </li>
              <li>
                <span>{{ `${role.name}` }}</span>
              </li>
            </ul>
          </div>
        </div>
        <div class="view-details-header-right">
          <button
            class="delete"
            @click="deleteRole"
            v-if="$currentUserCan($permissions.PERM_EDIT_ROLES) && !role.system"
          >
            <i class="far fa-trash-alt"></i>
            <span>{{ $t("COMMON.DELETE") }}</span>
          </button>
          <button
            class="edit"
            @click="editRole"
            v-if="
              $currentUserCan($permissions.PERM_DELETE_ROLES) && !role.system
            "
          >
            <i class="far fa-edit"></i>
            <span>{{ $t("COMMON.EDIT") }}</span>
          </button>
        </div>
      </div>
      <tabs
        fill
        class="flex-column flex-md-row"
        tabNavWrapperClasses="nav-wrapper"
        tabNavClasses="nav elite-tabs"
        value="global"
      >
        <tab-pane title="global" id="1" :active="true">
          <span slot="title">
            {{ $t("COMMON.GENERAL_INFORMATIONS") }}
          </span>
          <role-view-global :role="role" />
        </tab-pane>

        <tab-pane
          title="files"
          id="998"
          v-if="
            $currentUserCan($permissions.PERM_VIEW_ANY_FILES) &&
            role.organization
          "
        >
          <span slot="title">
            {{ $t("COMMON.FILES") }}
          </span>
          <div class="elite-tabs-wrapper-content">
            <list-file-component :object="role" />
          </div>
        </tab-pane>

        <tab-pane
          title="logs"
          id="999"
          v-if="$currentUserCan($permissions.PERM_VIEW_ANY_LOGS)"
        >
          <span slot="title">
            {{ $t("COMMON.LOGS") }}
          </span>
          <role-view-logs :role="role" />
        </tab-pane>
      </tabs>
    </div>
  </div>
</template>

<script>
import { cloneDeep } from "lodash";
import {
  Table,
  TableColumn,
  DropdownMenu,
  DropdownItem,
  Dropdown,
  Tooltip,
  Select,
  Option,
  Button,
} from "element-ui";
import { Tabs, TabPane } from "@/components";
import ListFileComponent from "@/components/File/ListFileComponent.vue";
import RoleViewLogs from "../partials/RoleViewLogs.vue";
import RoleViewGlobal from "../partials/RoleViewGlobal.vue";

export default {
  layout: "DashboardLayout",

  components: {
    [Tooltip.name]: Tooltip,
    [Table.name]: Table,
    [TableColumn.name]: TableColumn,
    [Dropdown.name]: Dropdown,
    [DropdownItem.name]: DropdownItem,
    [DropdownMenu.name]: DropdownMenu,
    [Select.name]: Select,
    [Option.name]: Option,
    [Button.name]: Button,
    Tabs,
    TabPane,
    RoleViewGlobal,
    RoleViewLogs,
    ListFileComponent,
  },

  mixins: [],

  props: {
    roleId: {
      type: [String, Number],
      required: true,
    },
  },

  data() {
    return {
      role: null,
    };
  },

  computed: {},

  watch: {},

  created() {
    this.get();
  },

  methods: {
    async get() {
      try {
        await this.$store.dispatch("roles/get", this.roleId);
        this.role = this.$store.getters["roles/role"];
      } catch (error) {
        this.$notify({
          type: "danger",
          message: this.$t("ERRORS.SOMETHING_WENT_WRONG"),
        });
      }
    },
    editRole() {
      this.$emit("onEditRole", this.role);
    },

    deleteRole() {
      this.$emit("onDeleteRole", this.role);
    },
  },
};
</script>
